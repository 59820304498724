.small-images{
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-wrap: row nowrap;
    height: auto;
    overflow: hidden;
    width: auto;
}

img{ 
}

li{
    list-style-type: none;
    flex: 1;
}

.img-footer{
    transition: transform .2s;
    margin: 0 auto;
    width: 100%;

}

.img-footer:hover{
    transform: scale(1.5);
    cursor: pointer;
}