.contact-form{
    background-color: #f7f7f7;
    padding-top: 2.5em;
    padding-bottom: 2em;

}

.contact-page{
    background-color: #f7f7f7;

}

.form-row{
    position: relative;
    flex: 1;
    padding-bottom: 1em;
}

.input-label{
    position: absolute;
    top: 15%;
    left: 5%;
}

.form-input{
    border-radius: 0px;
    border: 1px solid #c5c5c5;
    height: 3em;
    width: 100%;
    background-color: white;
    padding-left: 20px;
}

.dropdown{
    padding-left: 10px;
    color: rgb(95, 95, 95);

}

.large-area{
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.dropdown>option{
    color: rgb(95, 95, 95);
}

.form-row>label{
    color: rgb(95, 95, 95);

}

.form-input:focus{
    border: 1px solid #83d4b6;
}


textarea:focus{
    border: 1px solid #83d4b6;
}

textarea{
    height: 15em;
}

.form-header{
    text-align: center;
    background-color: #eaeaea;
    padding-top: 1em;
    padding-bottom: 1em;
    border: 1px solid #c5c5c5;
}

.contact-fields{
    background-color: #f2f2f2;
    padding: 2em;
    border-bottom: 1px solid #c5c5c5;
    border-left:  1px solid #c5c5c5;
    border-right:  1px solid #c5c5c5;
}

.office-hours-parent{
    padding-top: 2.5em;

}


.office-hours-inner{
    color: rgb(95, 95, 95);
    display: flex;
    justify-content: space-between;
    border: 1px solid #c5c5c5;
    background-color: #f2f2f2;
}

.office-hours-inner div{
    padding: 2em;
}

.office-text{
    flex: 1;
    background-color: #303030;
    color: white;
    align-items: center;
    display: flex;

}

.office-text h4{
    margin: 0;
}

.office-is-open{
    flex: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* contact us with directions styles */
.directions-parent{
    padding-top: 2.5em;
}


.contact-directions{
    color: rgb(95, 95, 95);
    display: flex;
    padding-top: 2.5em;
    justify-content: space-between;
}


.contact-details{
    border-top: 1px solid #c5c5c5;
    border-left:  1px solid #c5c5c5;
    border-right:  1px solid #c5c5c5;
    background-color: #f2f2f2;
    padding: 0;
    flex: 1;
    margin: 0;
    margin-right: 1.5em;
}

.border-bot{
    border-bottom: 1px solid #c5c5c5;
}

.contact-details{
    cursor: pointer;
}


.contact-details li:hover{
    background-color: #eaeaea;
}

.map-pin{
    transition: transform .2s;  
}

.map-banner-text{
    transition: transform .2s;  
}


.map-banner{
    margin-left: 1.5em;
    background-color: #7cdc66;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.direction-btn{
    color: white;
    text-align: center;
}

.map-banner:hover .map-pin{
    transform: scale(1.5);
}

.map-banner:hover .map-banner-text{
    transform:translateY(10px);
}

/* directions contact media query */
@media(max-width: 798px){
    .contact-directions{
        display: inline;
    }
    .contact-details, .map-banner{
        margin: 0;
    }

    .direction-btn{
        padding-top: 1.5em;
        padding-bottom: 1.5em;
    }
}



/* office hours media query */
@media(max-width:900px){
    .office-hours-inner{
        display: inline;
    }

    .office-text{
        justify-content: center;
        padding: 1em;
    }

    .office-is-open{
        background-color: #f2f2f2;
        border-bottom: 1px solid #c5c5c5;
        border-left:  1px solid #c5c5c5;
        border-right:  1px solid #c5c5c5;
    }

    .office-hours-inner{
        border: none;
    }

}

