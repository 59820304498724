.smaller-footer{
    padding-top: 3em;
    padding-bottom: 2em;
}

.small-text-footer{
    font-size: .67em;
}

.footer-main{   
    background-color:#3a3947;
}

a:hover{
    text-decoration: none;
    color: rgba(255,255,255,0.5)
  }

.text-grey{
    color: rgba(255,255,255,0.5);
}

.footer-bigger{
    padding-bottom: 3em;
    padding-top: 3em;
}

.footer-icons svg, b{
    margin-right: 10px;
}

.footer-icons svg, b :hover{
    cursor: pointer;
}

.rotate-icon:hover{
    transform: rotate(20deg);
}

ul{
    padding-left: 15px;
}

.btn-green a{
    color: white;
}