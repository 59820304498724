.carousel-parent{
    position: relative;
    height: 100vh;
}

.carousel-parent img{
    width: 100%;
    height:  100%;
    object-fit: cover;
}

.under-carousel-text:hover{
    color: white;
}

.img-row{
    display: flex;
    max-width: 100%;
    height: auto;
}

.all-carousel{
    overflow: hidden;
}

.banner-text{
    position: absolute;
    top: 50%;
    /* right: 50%; */
    color: white;
    padding-left: 10em;
    margin: auto;
}


.home-slider{
    position: relative;
}

.carousel-footer-parent{
    display: flex;
}

.carousel-footer{
    border-top: 1px solid white;
    height: 15vh;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.under-carousel{
    position: absolute;
    top: 84.49%;
    width: 100%;
}

.under-carousel-text{
    padding-left: 2em;
    align-items: center;
}

.footer-1:hover{
    background-image: url('../imgs/galery5.jpg');    
    background-size: cover;
}

.footer-2:hover{
    background-image: url('../imgs/gal5.jpg');   
    background-size: cover;
}


.footer-3:hover{
    background-image: url('../imgs/galery4.jpg'); 
    background-size: cover;
}

.nav-bar-front{
    position: absolute;
    top: 0%;
    width: 100%;
}

.carousel-parent img{
    filter: brightness(75%);
}


@media(max-width: 800px){
    .carousel-footer-parent{
        display: inline;
    }

    .under-carousel{
        position: relative;
    }

    .footer-1{
        background-image: url('../imgs/galery5.jpg');    
        background-size: cover;        
    }
    
    .footer-2{
        background-image: url('../imgs/gal5.jpg');   
        background-size: cover;
    }
        
    .footer-3{
        background-image: url('../imgs/galery4.jpg'); 
        background-size: cover;
    }

    .carousel-footer{
        height: 10vh;
    }
    .slick-list{
        max-height: 900px !important;
        overflow: hidden !important;
    }
    .banner-text{
        padding-left: 3em;
    }
    .carousel-footer{
        height: 20vh;
    }

}