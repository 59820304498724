.map-btn{
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: #7cdc66;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
}


.map-btn:hover{
    cursor: pointer;
    background-color: rgb(95, 189, 0);
}

.maps-btn-parent{
    display: flex;
}

.maps-btn-parent div{
    flex: 1;
}




.logo-title{
    height: 50px;
    width: 50px;
    filter: invert(98%) sepia(52%) saturate(1513%) hue-rotate(43deg) brightness(99%) contrast(74%)
}

.map-category{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
}

.places-list{
    column-count: 3;
    margin-bottom: 3em;
}

.location-list{
    text-align: center;
    padding: 1.5em;
    color: rgb(95, 95, 95);
}

.location-list:nth-child(odd) {
    border-bottom: solid 1px rgb(181, 181, 181);
    border-top: solid 1px rgb(181, 181, 181);
  }
  
  .location-list:nth-child(even) {
    border-bottom: solid 1px rgb(181, 181, 181);
  }

  @media(max-width: 1090px){
    .maps-btn-parent{
        display: block;
        width: 100%;
    }

    .maps-btn-parent div{
        margin-bottom: 3px;
    }
}

@media(max-width: 890px){
    .places-list{
        column-count: 1;
    }
    
    .location-list:nth-child(odd) {
        border-bottom: solid 1px rgb(181, 181, 181);
        /* border-top: solid 1px rgb(181, 181, 181); */
      }
      
      .location-list:nth-child(even) {
        border-bottom: none;
      }
      .location-list:last-child {          
        border-bottom: solid 1px rgb(181, 181, 181);
      }
}

.map-div{
    height: 400px;
}