.show-amenities{
    overflow: hidden;
}

.all-amenities{
    margin-bottom: 2em;
}


.hidden-amenities, .hidden-amenities-two{
    overflow: hidden;
    animation: all ease-in-out 0.7s;
    transition: all 1s ease-out;
    height: 13em;
}

.height-0{
    /* transform: translateY(-100%); */
    height: 0;
    transition: all 0.75s ease-out;
}

.hide{
    overflow: hidden;
}
.amenities-items{
    margin-bottom: 0;
}


.first-item, .second-item{
    border-bottom: 1px solid #c5c5c5;
    text-align: left;
}

.first-item{
    border-right: 1px solid #c5c5c5;
}

.amenities-items{
    column-count: 2;
    column-gap: 0px;   
} 

.amenities-items>li{
    padding: 1.2em;
    padding-left: 2em;
    padding-right: 2em;
    color: rgb(95, 95, 95);
    width: 100%;
}

.amenities-header{
    display: flex;
    justify-content: between;
    flex-direction: row-reverse;   
}

.amenities-header-two{
    display: flex;
    justify-content: between;  
}

.amenity-text, .amenity-img, .amenity-text-two{
    flex: 5;
}

img{
    width: 100%;
    height: auto;
}

.amenity-text{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.9em;
}

.amenity-text-two{
    display: flex;
    justify-content: center;
    align-items: center;

}

@media(max-width:780px){
    .amenities-items{
        column-count: 1;
    } 

    .first-item{
        border-right: none;
    }

    .amenities-header{
        display: block;
    }

    .amenity-text{
        padding: 1em;
    }
}