nav{
    background-size: cover;
    color: white;
    align-items: baseline;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: 1px solid #ffffff;
    height: 10vh;
    /* align-items: center; */
}

li{
    list-style: none;
}

.nav-items, .nav-btn{height: 100%;}


.small-text a{
    color: white;
}


.all-links{
    white-space: nowrap;
    margin: 0;
}

.small-text{
    margin-right: 1.2em;
}

.nav-toggle{
    border-left: 1px white solid;
}


.nav-links > li{
    margin-right: 1em;
}

.toggle-btn{
    color: white;
}

.lease-text{
    white-space: nowrap;
}

@media(max-width: 978px){
    .nav-links{
        display: none;
    }
}


@media(max-width: 800px){
    .nav-links{
        display: none;
    }

    .lease-text{
        display: none;
    }

    .nav-toggle{
        border: none;
        justify-content: end;
    }
    .nav-only{
        background-image: none;

        background-color: #2f2e3d;
    }
    .small-text{
        display: none;
    }
}

.nav-modal-flex{
    height: 90vh;
    background-color: #2f2e3d;
    color: white; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-text > li{
    font-size: 30px;
    text-align: center;
}

.modal-text li a:hover{
    color:  #7cdc66
}

.modal-text li a{
    color:  white;
}

.nav-modal{
    overflow: hidden;
    display: none;
}

.bg-nav{
    background-color: #2f2e3d;
}

a:hover{
    color: white;
}

.toggle-btn:hover{
    cursor: pointer;
}

.bi-building{
    height: 50px;
    width: 50px;
}